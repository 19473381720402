import services from '@/services'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // axios
        //   .get('/apps/user/view', { params: queryParams })
        //   .then(response => resolve(response))
        //   .catch(error => reject(error))
        services.ApiServices.list('user', queryParams).then(
          ({ data }) => resolve(data),
          err => reject(err),
        )
      })
    },
    fetchUser(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        // axios
        //   .get(`/apps/user/view/${uuid}`)
        //   .then(response => resolve(response))
        //   .catch(error => reject(error))
        services.ApiServices.get('user', uuid).then(
          ({ data }) => resolve(data),
          err => reject(err),
        )
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        // axios
        //   .post('/apps/user', { user: userData })
        //   .then(response => resolve(response))
        //   .catch(error => reject(error))
        services.ApiServices.add('user', userData).then(
          ({ data }) => resolve(data),
          err => reject(err),
        )
      })
    },
  },
}
