<template>
  <v-card>
    <v-card-text>
      <v-row class="align-center">
        <v-col cols="1">
          <v-checkbox
            v-model="selected"
          ></v-checkbox>
        </v-col>
        <v-col cols="2">
          <div class="img">
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="100%"
              max-width="100%"
              src="https://picsum.photos/id/11/500/300"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="content">
            <h4>{{ title }}</h4>
            <p>{{ content }}</p>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="date">
            {{ date }}
          </div>
        </v-col>
        <v-col cols="2">
          <div class="date">
            <v-btn
              v-if="!readTime"
              outlined
              color="primary"
              :loading="isLoadingButton"
              :disabled="isLoadingButton"
              @click="handleRead"
            >
              baca
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CardNotification',
  props: {
    uuid: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    content: {
      type: String,
      default: () => '',
    },
    date: {
      type: String,
      default: () => '',
    },
    action: {
      type: String,
      default: () => '',
    },
    selected: {
      type: Boolean,
      default: () => false,
    },
    readAt: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      readTime: '',
      isLoadingButton: false,
    }
  },
  watch: {
    selected: {
      handler() {
        this.$emit('select', {
          uuid: this.uuid,
          isSelected: this.selected,
        })
      },
    },
  },
  mounted() {
    this.readTime = this.readAt
  },
  methods: {
    handleRead() {
      this.isLoadingButton = true
      this.$emit('read', {
        uuid: this.uuid,
        onSuccess: () => {
          this.isLoadingButton = false
          this.readTime = new Date()
        },
        onFailure: () => {
          this.isLoadingButton = false
        },
      })
    },
  },
}
</script>

<style>
</style>
